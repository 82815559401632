import{
  makeResponsive,
  placeTitle,
  createSpace,
  placeQuestion,
  placeComment,
  createAxes,
  writeHTMLText,
  drawPoint,
  setSize,
  labelIt,
  placeMarker,
  drawCircle,
  placeImage,
  placeShuffle,
  placeTest,
  drawArrow,
  drawAngle,
  drawSegment,
  placeBWhite,
  placeWhite,
  placeBCircles,
  placeCircles,
  placeChecks,
  placeCross,
  placeExclaim,
  hoverMe,
  placeLogo,
  drawMarker,
  toggle,
  toggleTF,
  placeFingers,
  placeAnswers,
  drawTriangle,
  placePlay,
  placeStartOver
} from '../Utils.js'
const Boxes = {
  box1: function () {
///////////////////////////////////GLOBAL SETTINGS//////////////////////
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.line.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
//////////////////////////////////LOCAL SETTINGS//////////////////////////////////
var graph =createSpace(-8,8,-7,9);
makeResponsive(graph);
graph.suspendUpdate();
graph.options.layer['image']=26;
graph.options.layer['text']=2;
graph.options.layer['html']=2;
graph.options.layer['line']=2;
graph.options.layer['point']=10;
graph.options.layer['circle']=1;
graph.options.image.highlight=false;
/*********************GRAPH DIMENSIONS*******************/
const boundingBox = graph.attr.boundingbox;
const positionX = (boundingBox[2]+boundingBox[0])/2;
const positionY = (boundingBox[1]+boundingBox[3])/2;
const height = (boundingBox[1]-boundingBox[3])/2;
/**********************PUT AXES **********************/
var ax = createAxes(graph);
/***************** PUT AXES TITLE *******************/
ax[0].setAttribute({name:'x', ticks:{visible:false}});
ax[1].setAttribute({name:'y', ticks:{visible:false}});
/***********************SCORING *******************/
var yourScore =0; var yourMissed =0; var yourWrong =0; var yourTotal=0;
var scoreCard = writeHTMLText(graph, positionX, positionY+1, function(){return 'Your Score ( &check; ): '+ yourScore+'/5'});
var missedCard = writeHTMLText(graph, positionX, positionY, function(){return 'Missed Questions ( ! ): '+ yourMissed+'/5'});
var wrongCard = writeHTMLText(graph, positionX, positionY-1, function(){return 'Wrong Answers ( x ): '+ yourWrong+'/5'});
scoreCard.setAttribute({visible:false});
missedCard.setAttribute({visible:false});
wrongCard.setAttribute({visible:false});
/*************************ANSWERS*********************/
var index_selected_answer = -1;
var i =0;
var aList =[4,  1, -2,  3, -2];
var bList = [2,  2,  2,  2,  3];
var cList = [-2, -3,  4, -1,  2];
const answers = [['x_1 = 2 & x_2 = -1', 'x_1 = -1 & x_2 = 0.5', 'x_1 = -2 & x_2 = 0.5', 'x_1 = -1 & x_2 = -2'],
['x_1 = 1 & x_2 = -3', 'x_1 = 1 & x_2 = 3', 'x_1 = -1 & x_2 = -3', 'x_1 = -1 & x_2 = -3'],
['x_1 = -1 & x_2 = -2', 'x_1 = 1 & x_2 = 2', 'x_1 = -1 & x_2 = 3', 'x_1 = -1 & x_2 = 2'],
['x_1 = -1/3 & x_2 = 1', 'x_1 = -1/2 & x_2 = 1/3', 'x_1 = -1 & x_2 = 1/3', 'x_1 = -1 & x_2 = 1/2'],
['x_1 = -0.5 & x_2 = -2', 'x_1 = -0.5 & x_2 = 2', 'x_1 = 0.5 & x_2 = 2', 'x_1 = -0.5 & x_2 = 3']];
const index_right_answer = [1,0,3,2,1];
var txtB = function(){
  if(bList[i] > 0)
  {return  '+ '+ bList[i]}
  else {
   return  bList[i]
  }
}
var txtC = function(){
  if(cList[i] > 0)
  {return  '+ '+cList[i]}
  else {
   return  cList[i]
  }
}
/***************************************************************************************/
var eqn = writeHTMLText(graph, -7.5, 2.5, function(){return 'Equation: '+ aList[i]+' x^2 ' + txtB() +' x ' + txtC() + ' = 0'});
eqn.setAttribute({anchorX:'left', color:'black'});
/****************************************/
var point1 = drawPoint(graph, function(){return (-bList[i] + Math.sqrt(bList[i]*bList[i] - 4*aList[i]*cList[i]))/(2*aList[i])}, 0);
point1.setAttribute({strokeColor:'black', fillColor:'white'});
setSize(graph, point1, 3);
var point2 = drawPoint(graph, function(){return (-bList[i] - Math.sqrt(bList[i]*bList[i] - 4*aList[i]*cList[i]))/(2*aList[i])},0);
point2.setAttribute({strokeColor:'black', fillColor:'white'});
setSize(graph, point2, 3);
/************************************************/
var graph1 = graph.create('functiongraph', [function(x){return cList[i]*1+ aList[i]*x*x + bList[i]*x;}, -5, 5], {strokeColor:'red', strokeWidth:4});
/***********************************************************************************/
/****************PUT TITLE ****************************/
var question = placeQuestion(graph, 'The solutions of the shown quadratic equation are &mdash;');
var comment = placeComment(graph, '');
var note = writeHTMLText(graph, positionX, positionY+height/2., 'You did not make a selection.');
note.setAttribute({visible:false});
//print(graph, ()=>(alpha.Value()*180/Math.PI).toFixed(1));
///////////////////////////GRAPHICS MODULES//////////////////
var bck =placeWhite(graph);
var show =placeBCircles(graph);
var hide=placeCircles(graph);
var check = placeChecks(graph);
var cross = placeCross(graph);
var exclaim = placeExclaim(graph);
var pointers = placeFingers(graph);
hide[0].setAttribute({visible:false});
/***************************BUTTON MODULES******************/
var test =placeTest(graph,'left');
hoverMe(graph, test, 'Check Your Answer', -20, 0);
/**/
var next = placePlay(graph);
hoverMe(graph, next, 'Next Question', 0, 0);
/**/
var redo = placeStartOver(graph);
redo.setAttribute({visible:false});
hoverMe(graph, redo, 'Start Over', 0, 0);
/***************************************/
var i=0; var k=0; var vis=false;
///////////////////////////////////////////////////////////////
var ansList = ['x_1 = 2 & x_2 = -1', 'x_1 = -1 & x_2 = 0.5', 'x_1 = -2 & x_2 = 0.5', 'x_1 = -1 & x_2 = -2'];
var ansArray = placeAnswers(graph, ansList);
for(let ij=0; ij<=3; ij++)
{
  ansArray[ij].on('down', function(){
  ansArray.forEach(function(item){item.setAttribute({color:'grey'})});
  pointers.forEach(function(item){item.setAttribute({visible:false})});
  ansArray[ij].setAttribute({color:'black'});
  pointers[ij].setAttribute({visible:true});
  index_selected_answer = ij.valueOf();})
}
////////////////////////////////////////////////////////////////////////
var hint =writeHTMLText(graph, positionX, positionY, '<ol> <li> The triangle OAB is an isosceles triangle.</li><br><li> Per interior angle theorem, the &angle;AOB = 2 &angle; ACB. </li> <br><li> The three angles of a triangle sum upto to 180^o. </li></ol>');
hint.setAttribute({visible:false, color:'white'});
/**************************TEST BUTTON ***********************/
test.on('down', function()
{
  note.setAttribute({visible:false});
  if(i<=aList.length-1)
  {
    if(index_selected_answer == index_right_answer[i] && yourTotal<aList.length)
	  { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
      }
      else if(index_selected_answer != index_right_answer[i] && yourTotal<aList.length && index_selected_answer != -1)
      { //is incorrect
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:true});
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
        ansArray[index_selected_answer].setAttribute({color:'red'});
      }
	  else if (index_selected_answer == -1 && yourTotal<aList.length)
	  {   check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:true});
        note.setAttribute({visible:true});
	  }
  }
});
/*****************************SHUFFLE BUTTON ***********************/
next.on('down', function(){
  note.setAttribute({visible:false});
  if(i<=aList.length-1)
  {
    if(index_selected_answer == index_right_answer[i] && yourTotal<aList.length)
	  { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
        yourScore = yourScore+1;
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
      }
      else if(index_selected_answer != index_right_answer[i] && yourTotal<aList.length && index_selected_answer != -1)
      { //is incorrect
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:true});
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
        ansArray[index_selected_answer].setAttribute({color:'red'});
		    yourWrong = yourWrong+1;
      }
	  else if (index_selected_answer == -1 && yourTotal<aList.length)
	  {   check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:true});
		    yourMissed = yourMissed+1;
	  }
	  yourTotal = yourScore + yourWrong + yourMissed;
  }
  if(i==aList.length-1)
  {
  bck.setAttribute({opacity:1});
  scoreCard.setAttribute({visible:true});
  missedCard.setAttribute({visible:true});
  wrongCard.setAttribute({visible:true});
  redo.setAttribute({visible:true});
  ansList[0]=answers[0][0];
  ansList[1]=answers[0][1];
  ansList[2]=answers[0][2];
  ansList[3]=answers[0][3];
  ansArray.forEach(function(item){item.setAttribute({visible:false})});
  question.setAttribute({visible:false});
  eqn.setAttribute({visible:false});
//  beta.setAttribute({visible:false});
  }
  else{
    scoreCard.setAttribute({visible:false});
    missedCard.setAttribute({visible:false});
    wrongCard.setAttribute({visible:false});
    i=i+1;
    ansList[0]=answers[i][0];
    ansList[1]=answers[i][1];
    ansList[2]=answers[i][2];
    ansList[3]=answers[i][3];
  }
  hide[i].setAttribute({visible:false});
  hint.setAttribute({visible:false});
  pointers.forEach(function(item){item.setAttribute({visible:false})});
  ansArray.forEach(function(item){item.setAttribute({color:'grey'})});
  index_selected_answer = -1;
  /////////////////////////ALL CHANGES GO HERE////////////////////////
  //Ptm.moveTo([aList[i], 8], 200);
  ///////////////////////////////////////////////////////////////////
});
/************************************************/
redo.on('down', function(){
	//Hiding all blue circles
	  hide.forEach(function(item){item.setAttribute({visible:true})});
    hide[0].setAttribute({visible:false});
  //Hiding all check marks
    check.forEach(function(item){item.setAttribute({visible:false})});
  //Hiding all cross marks //
    cross.forEach(function(item){item.setAttribute({visible:false})});
  //Hiding all exclaimation marks
	  exclaim.forEach(function(item){item.setAttribute({visible:false})});
  ///////////////resetting scores//////////////////
    note.setAttribute({visible:false});
	  yourScore=0;
	  yourMissed=0;
	  yourWrong=0;
	  yourTotal=0;
	  scoreCard.setAttribute({visible:false});
	  missedCard.setAttribute({visible:false});
    wrongCard.setAttribute({visible:false});
  /////////////////////////////////////////////
	  i=0;
	  ansList[0]=answers[0][0];
	  ansList[1]=answers[0][1];
	  ansList[2]=answers[0][2];
	  ansList[3]=answers[0][3];
	  bck.setAttribute({opacity:0});
	  pointers.forEach(function(item){item.setAttribute({visible:false})});
    ansArray.forEach(function(item){item.setAttribute({visible:true, color:'grey'})});
	  question.setAttribute({visible:true});
    comment.setAttribute({visible:true});
	  hint.setAttribute({visible:false});
	  index_selected_answer = -1;
    redo.setAttribute({visible:false});
    ////////////////////////ALL CHANGES GO HERE/////////////
    //Ptm.moveTo([aList[0], 8], 200);
    ////////////////////////////////////////////////////////
    eqn.setAttribute({visible:true});
  //  beta.setAttribute({visible:true});
});
/********************PLACE LOGO ********************/
var edliy = placeLogo(graph);
hoverMe(graph, edliy, 'Tap to see a hint', 0, 0);
edliy.on('down',
function(){
  k = toggle(k);
	vis = toggleTF(vis);
  bck.setAttribute({opacity:k});
	scoreCard.setAttribute({visible:false});
	missedCard.setAttribute({visible:false});
	wrongCard.setAttribute({visible:false});
  hint.setAttribute({visible:vis});
	question.setAttribute({visible:!vis});
	ansArray.forEach(function(item){item.setAttribute({visible:!vis})});
  note.setAttribute({visible:false});
  ////////////////////////Code specific changes go here/////////////////
	eqn.setAttribute({visible:!vis});
//  beta.setAttribute({visible:!vis});
  //////////////////////////////////////////////////////////////////////
});
graph.unsuspendUpdate();
/******************END OF FILE ***************************/
}
}
export default Boxes;
