<template>
  <div>
    <h3 ref="intro">
      Quadratic Equation
    </h3>
    <p>
      <br>
      A quadratic equation is a polynomial equation that is second order in its primary variable. For example, a quadratic equation in \(x\) is given as:
      $$ a x^2 + b x +c =0$$
      where the coefficients \(a \), \(b\) and \(c\) are real numbers with the condition that \(a \ne 0\).
    </p>
    <h3 ref="sol">
      Solutions of a Quadratic Equation &ndash; Quadratic Formula
    </h3>
    <p>
      <br>
      Every quadratic equation has two solutions &ndash; which are given as:
      $$ x = \frac{-b \pm \sqrt{b^2 - 4ac}}{2a} $$
      The plus-minus sign (\(\pm\)) indicates that the equation has two solutions. Expressed separately, the two solutions can be written as:
      $$x_1 = \frac{-b + \sqrt{b^2 - 4ac}}{2a}$$
      and
      $$x_2 = \frac{-b - \sqrt{b^2 - 4ac}}{2a}$$
      These solutions are also called the roots of the quadratic equation.
    </p>
    <h3 ref="nature">
      Discriminant & Number of Real Solutions
    </h3>
    <p>
      The term term inside the square root sign in the quadratic formula i.e. \(b^2-4ac\) is called the discriminant, and is often denoted by D. A quadratic equation can either have one solution, two distinct, real solutions, or two distinct, complex solutions. The discriminant determines the number and nature of the solution.
      <ul style="list-style-type:square;">
        <li>
          <h5> D = b<sup>2</sup> -4 ac &gt; 0 </h5>
          The two solutions of the equation are real and distinct.
        </li>
        <li>
          <h5> D = b<sup>2</sup> -4 ac = 0 </h5>
          The two solutions of the equation are real and indistinct (equal to each other). In other words, the equation has only one real solution.
        </li>
        <li>
          <h5> D = b<sup>2</sup> -4 ac &lt; 0 </h5>
          The two solutions of the equation are complex and distinct. In other words, the equation has no real solution.
        </li>
      </ul>
    </p>
    <h3 ref="graph">
      MagicGraph | Discriminant & Number of Solutions
    </h3>
    <p>
      <br>
      Graphically, a quadratic function, such as \(y =a x^2+bx+c\), describes a parabola when graphed in x and y. Then, the two solutions of the quadratic equation \(a x^2+ b x +c =0\) represent the points where this parabola intersects with the x-axis (\(y=0\)).
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'QuadraticEquation',
  created: function () {
    this.$store.commit('navigation/resetState');
    this.$store.commit('navigation/changeTitle', 'Quiz on Quadratic Equation');
    //this.$store.commit('navigation/changeMenu', 'Quadratic Equation');
    let newTopics = [
      {title: 'Quadratic Equation', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Solution to Quadratic Equation', img:'/assets/number-2.svg', action: () => this.goto('sol')},
      {title: 'Nature of Solutions', img:'/assets/number-3.svg', action: () => this.goto('nature')},
      {title: 'Derivation of Quadratic Formula', img:'/assets/number-4.svg', action: () => this.goto('der')},
      {title: 'Graphical Interpretation of Solution', img:'/assets/touch.svg', action: () => this.goto('graph')}
    ];
    //this.$store.commit('navigation/replaceTopics', newTopics);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Quadratic Equation',
          //titleTemplate: '%s - Learn interactively',
          meta: [
                {vmid:'description', name: 'description', content: 'Learn interactively about the discriminant of a quadratic equation'}
                ]
        }
   },
}
</script>

<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
